<template>
  <div class="my-6" id="comparison">
    <div class="is-flex is-align-items-center border-bottom pb-2 mb-3">
      <img src="/img/assets/comparison.png" class="mr-3" alt style="height: 20px"/>
      <p class="has-text-weight-bold mb-0">{{ $t('comparison.comparison') }}</p>
    </div>
    <comparison-chart :death-data="deathData" :disease-data="diseaseData" :default-parameters="['ph', 'DO', this.$t('warehouse.daily')]" :selected-cycle="selectedCycle" :selected-pond="selectedPond"/>
  </div>
</template>

<script>
import ComparisonChart from "@/components/Farm/Comparison/ComparisonChart";

export default {
  name: "ReportComparison",
  components: {ComparisonChart},
  props: {
    diseaseData: Array,
    deathData: Array,
    selectedPond: Object,
    selectedCycle: Object,
  },
}
</script>
