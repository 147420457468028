<template>
  <div id="death" v-if="death.length">
    <div class="is-flex is-align-items-center border-bottom pb-2 mb-3">
      <img src="/img/assets/death-count.png" class="mr-3" alt style="height: 20px"/>
      <p class="has-text-weight-bold mb-0">{{ $t('death.death') }}</p>
    </div>

    <template v-if="!hideTable">
      <div v-for="(death, key) in death" :key="`death-${key}`" class="mb-4">
        <p class="has-text-weight-bold mb-2 pb-1 border-bottom">{{ $dateFormat(death.date) }}</p>

        <div class="b-table mb-2" v-if="death.data && death.data.length">
          <div class="table-wrapper">
            <table class="table is-bordered">
              <tbody>
              <tr class="header-row">
                <th>{{ $t('farm.pond') }}</th>
                <th>DOC</th>
                <th>MBW</th>
                <th>{{ $t('death.weight') }}</th>
                <th>{{ $t('death.shrimp') }}</th>
                <th>{{ $t('death.accumulative') }}</th>
                <th>{{ $t('stock.source') }}</th>
                <th>{{ $t('desc') }}</th>
              </tr>
              </tbody>
              <tbody>
              <tr v-for="(item, key) in death.data" :key="key">
                <td>{{ item.name }}</td>
                <td>{{ docByStock(item.pond_id, item.stock_id, death.date) }}</td>
                <td>{{ $numberFormat(item.mbw) }}</td>
                <td>{{ $numberFormat(item.weight) }}</td>
                <td>{{ $numberFormat(item.amount) }}</td>
                <td class="has-text-danger">
                  {{ $numberFormat(Math.floor(cumulativeDeath(item.pond_id, item.created_at))) }}
                </td>
                <td>{{ item.pl_source }}</td>
                <td>{{ item.note || '-' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p class="has-text-centered" v-else>{{ $t('no') }} Data</p>
      </div>
    </template>

    <div class="my-3" v-if="isCycle">
      <div class="columns is-tablet">
        <div class="column">
          <apexchart v-if="chart.options && chart.series"
                     type="area"
                     height="350"
                     :options="chart.options"
                     :series="chart.series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "ReportDeath",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    death: Array,
    stocks: Array,
    isCycle: Boolean,
    hideTable: Boolean,
  },
  computed: {
    data() {
      return this.death.reduce((a, b) => {
        return a.concat(b.data.map(e => {
          e.created_at = b.date
          return e
        }))
      }, [])
    },

    chart() {
      let stock = this.stocks[0]
      return {
        series: [{
          name: this.$t('death.shrimp'),
          data: this.data.reduce((a, b) => {
            let find = a.find(e => e.x === b.created_at)

            if (!find)
              a.push({
                x: b.created_at,
                y: this.$number(b.amount)
              })
            else find.y += this.$number(b.amount)

            return a
          }, [])
        }, {
          name: this.$t('death.weight'),
          data: this.data.reduce((a, b) => {
            let find = a.find(e => e.x === b.created_at)

            if (!find)
              a.push({
                x: b.created_at,
                y: this.$number(b.weight)
              })
            else find.y += this.$number(b.weight)

            return a
          }, [])
        }],
        options: {
          chart: {
            type: 'area',
            height: 350,
            zoom: {
              enabled: true
            },
          },
          colors: ['#FF4060', '#3998EB'],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },

          xaxis: {
            type: 'datetime',
          },
          legend: {
            horizontalAlign: 'center',
            itemMargin: {
              horizontal: 5,
              vertical: 10
            },
          },
          tooltip: {
            x: {
              formatter: (value) => {
                return this.$dateFormat(value, 'DD MMM YYYY') + ' - DOC ' + (stock ? this.$getDOC(stock.stock_date, value) : '-')
              }
            }
          }
        }
      }
    },
  },
  methods: {
    docByStock(pond_id, stock_id, date) {
      let stock = this.stocks.find(e => e.stock_id === stock_id && e.pond_id === pond_id)

      if (stock) return this.$getDOC(stock.stock_date, date)

      return '-'
    },

    cumulativeDeath(pond_id, date) {
      return this.data.reduce((a, b) => {
        if (b.pond_id === pond_id && b.created_at < date)
          return a + b.amount
        return a
      }, 0)
    },
  }
}
</script>
