var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-navigation hide-print"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('b-menu',{attrs:{"activable":false}},[_c('b-menu-list',{attrs:{"label":"Overview"}},_vm._l((_vm.items.filter(function (e) { return !e.type; })),function(item,key){return _c('b-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
               el: item.id,
               lazy: false,
               offset: -100,
               force: true,
           }),expression:"{\n               el: item.id,\n               lazy: false,\n               offset: -100,\n               force: true,\n           }"}],key:key,attrs:{"label":item.label}})}),1),_c('b-menu-list',{attrs:{"label":"Data & Grafik Lainnya"}},_vm._l((_vm.items.filter(function (e) { return e.type === 'others'; })),function(item,key){return _c('b-menu-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
               el: item.id,
               lazy: false,
               offset: -100,
               force: true,
           }),expression:"{\n               el: item.id,\n               lazy: false,\n               offset: -100,\n               force: true,\n           }"}],key:key,attrs:{"label":item.label}})}),1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }