<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
  <div class="ml-auto mr-auto">
    <div v-if="!loading">
      <div style="max-width: 100%; width: 1000px;" class="has-text-right mb-5" id="print">
        <b-button type="is-primary is-gradient" icon-left="printer" outlined @click="print">
          Print
        </b-button>
      </div>

      <div id="report" style="max-width: 1000px">

        <report-header :selected-farm="selectedFarm" :ponds="ponds" :start-date="start_date" :end-date="end_date"
                       :is-cycle="isCycle"/>

        <section class="report">
          <!-- PDF Content Here -->

          <sg-tab v-model="selectedPondId" :items="pondsOptions" label="name" class="my-4 hide-print"></sg-tab>

          <template v-if="typeof selectedPondId === 'number'">
            <report-overview :stocks="stocks" :selected-stock="selectedStock" :selected-pond="selectedPond"/>

            <report-harvest :harvest="harvestData" :is-cycle="isCycle"/>
            <report-usage :is-cycle="isCycle" :usage="usageData"/>
            <report-comparison :death-data="deathDataByDate" :disease-data="diseaseData" :selected-pond="selectedPond"
                               :selected-cycle="selectedCycle"/>

            <report-by-date
                :wq="wqData" :wq-standar="wqStandar" :wq-charts="data.wq_charts" :wqs="wqs"
                :stocks="stocks" :type="type"
                :disease-date="diseaseDate" :death-date="deathDate"
                :is-cycle="isCycle"
                :cycle="selectedCycle"
                :start-date="start_date"
                :end-date="end_date"
                :all-date="allDate"
                :data="dataByDate"
                :death="deathData"
            />

<!--            <report-water-quality-->
<!--                :wq="wqData" :wq-standar="wqStandar" :wq-charts="data.wq_charts" :wqs="wqs"-->
<!--                :stocks="stocks" :type="type"-->
<!--                :disease-date="diseaseDate" :death-date="deathDate"-->
<!--                :is-cycle="isCycle"-->
<!--                :cycle="selectedCycle"-->
<!--                hide-table-->
<!--            />-->
            <report-products
                :product="productsData" :chart="productChartData" :stocks="stocks"
                :disease-date="diseaseDate" :death-date="deathDate"
                :is-cycle="isCycle" :cycle="selectedCycle"
                hide-table
            />
            <report-disease :disease="diseaseDataPond" :stocks="stocks"/>
            <report-death :is-cycle="isCycle" :death="deathData" :stocks="stocks" :death-by-date="deathDataByDate" hide-table/>
            <report-sampling :sampling="samplingData" :stocks="stocks" :disease-data="diseaseData" hide-table/>

            <report-navigation :items="navigation"/>
          </template>
          <template v-else-if="selectedPondId === 'harvest'">
            <report-harvest :stocks="stocks" :harvest="data.harvest" :total-harvest="data.total_harvest" :is-cycle="isCycle"/>
          </template>

        </section>
      </div>
    </div>

    <div v-else class="mt-6 pt-6">
      <report-loading :progress="progress" :total-promises="totalPromises" :done="done"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ReportHeader from "@/components/Farm/Report/ReportHeader";
// import ReportWaterQuality from "@/components/Farm/Report/ReportWaterQuality";
import ReportProducts from "@/components/Farm/Report/ReportProducts";
import ReportDeath from "@/components/Farm/Report/ReportDeath";
import ReportDisease from "@/components/Farm/Report/ReportDisease";
import ReportSampling from "@/components/Farm/Report/ReportSampling";
import ReportHarvest from "@/components/Farm/Report/ReportHarvest";
import ReportLoading from "@/components/Farm/Report/ReportLoading";
import ReportUsage from "@/components/Farm/Report/ReportUsage";
import ReportComparison from "@/components/Farm/Report/ReportComparison";
import ReportNavigation from "@/components/Farm/Report/ReportNavigation";
import Vue from "vue";
import SgTab from "@/components/Sg/SgTab";
import ReportOverview from "@/components/Farm/Report/ReportOverview";
import ReportByDate from "@/components/Farm/Report/ReportByDate";

let VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)

export default {
  name: "CycleReport",
  components: {
    ReportByDate,
    ReportOverview,
    SgTab,
    ReportNavigation,
    ReportComparison,
    ReportUsage,
    ReportLoading,
    ReportHarvest,
    ReportSampling,
    ReportDisease,
    ReportDeath,
    ReportProducts,
    // ReportWaterQuality,
    ReportHeader,
  },
  data: () => {
    return {
      wqs: [],
      data: {},
      selectedPondId: null,
      promises: [],
      totalPromises: 0,
      cycles: [],
      stocks: [],
      warehouse: [],

      chart: [],
      feedIndexSetting: null,

      done: 0,
      progress: 0,

      loading: false,

      allDate: [],
    }
  },
  computed: {
    ...mapGetters('farm', [
      'selectedFarm',
    ]),

    query() {
      return this.$route.query
    },

    ponds() {
      return JSON.parse(this.query.ponds)
    },

    pondsOptions() {
      let options = JSON.parse(this.query.ponds).map(e => {
        return {
          value: e.pond_id,
          ...e,
        }
      })

      if (this.data.harvest && this.data.harvest.length)
        options.push({
          value: 'harvest',
          name: this.$t('harvest.all')
        })

      return options
    },

    pond() {
      return this.ponds[0]
    },

    pond_ids() {
      return this.ponds.map(e => e.pond_id)
    },

    cycle_ids() {
      return this.ponds.map(e => e.cycle_id)
    },

    stock_ids() {
      return this.stocks.map(e => e.stock_id)
    },

    date() {
      return this.query.date ? JSON.parse(this.query.date) : null
    },

    cycle_start_date() {
      return this.cycles.reduce((a, b) => a < b.start_date ? a : b.start_date, this.cycles[0].start_date)
    },
    cycle_end_date() {
      return this.cycles.find(e => e.end_date === 0) ? this.$timestamp() : this.cycles.reduce((a, b) => a > b.end_date ? a : b.end_date, 0)
    },

    start_date() {
      return this.isCycle ? this.cycle_start_date : this.date[0]
    },
    end_date() {
      return this.isCycle ? this.cycle_end_date : (this.date[1] || this.date[0])
    },

    type() {
      return this.query.type
    },

    isCycle() {
      return this.type === 'Cycle'
    },

    diseaseDate() {
      if (this.diseaseData)
        return this.diseaseData.reduce((a, b) => {
          let date = this.$moment(b.created_at)
          let start = date.clone().add({days: -7})
          let end = date.clone().add({days: 7})
          let i = 0
          while (start < end) {
            let find = a.find(e => e.date === start.valueOf())
            if (find) {
              find.disease.push(b.disease_name)
              if (i >= 7) find.color = 'danger'
            } else {
              a.push({
                disease: [b.disease_name],
                date: this.$startDay(start).valueOf(),
                color: i < 7 ? 'warning' : 'danger'
              })
            }
            start = start.add({days: 1})
            i++
          }
          return a
        }, [])
      return null
    },
    diseaseData() {
      if (this.data.disease && this.data.disease.length) {
        let find = this.data.disease.find(e => e.pond.pond_id === this.selectedPondId)
        if (find) return find.data
      }
      return null
    },

    navigation() {
      let res = []

      res.push({id: '#overview', label: 'Overview'})
      if (this.harvestData && this.harvestData.length) res.push({id: '#harvest', label: this.$t('harvest.harvest')})
      if (this.usageData && this.usageData.length) res.push({id: '#usage', label: this.$t('warehouse.usage_summary')})
      res.push({id: '#comparison', label: this.$t('comparison.comparison')})
      if (this.dataByDate && this.dataByDate.length) res.push({id: '#daily-data', label: this.$t('daily_data')})
      // if (this.wqData && this.wqData.length) res.push({id: '#water-quality', label: this.$t('wq.wq')})
      if (this.productsData && this.productsData.length) res.push({type:'others',id: '#product', label: this.$t('warehouse.product')})
      if (this.diseaseDataPond && this.diseaseDataPond.length) res.push({type:'others',id: '#disease', label: this.$t('disease')})
      if (this.deathData && this.deathData.length) res.push({type:'others',id: '#death', label: this.$t('death.death')})
      if (this.samplingData && this.samplingData.length) res.push({type:'others',id: '#sampling', label: 'Sampling'})

      return res
    },

    selectedStock() {
      return this.stocks.find(e => e.pond_id === this.selectedPondId)
    },
    selectedPond() {
      return this.ponds.find(e => e.pond_id === this.selectedPondId)
    },
    selectedCycle() {
      return this.cycles.find(e => e.pond_id === this.selectedPondId)
    },

    usageData() {
      return this.data.usage.filter(e => e.pond.pond_id === this.selectedPondId)
    },

    wqData() {
      return this._.cloneDeep(this.data.wq).map(e => {
        e.data.data = e.data.data.filter(f => f.pond_id === this.selectedPondId)
        return e
      }).filter(e => e.data.data.length)
    },

    wqStandar() {
      return this._.cloneDeep(this.data.wq_standar).map(e => {
        e.data = e.data.filter(f => f.pond_id === this.selectedPondId)
        return e
      }).filter(e => e.data.length)
    },

    productsData() {
      return this._.cloneDeep(this.data.product).map(e => {
        e.data.data.data = e.data.data.data.filter(f => f.pond_id === this.selectedPondId)
        // e.data.data.probiotics = e.data.data.probiotics.filter(f => f.pond_id === this.selectedPondId)
        return e
      }).filter(e => e.data.data.data.length || e.data.data.probiotics.length)
    },

    diseaseDataPond() {
      return this._.cloneDeep(this.data.disease).map(e => {
        e.data = e.data.filter(f => f.pond_id === this.selectedPondId)
        return e
      }).filter(e => e.data.length)
    },

    deathData() {
      return this._.cloneDeep(this.data.death).map(e => {
        e.data = e.data.filter(f => f.pond_id === this.selectedPondId)
        return e
      }).filter(e => e.data.length)
    },

    deathDate() {
      if (this.deathDataByDate)
        return this.deathDataByDate.reduce((a, b) => {
          let date = this.$moment(b.date)
          let start = date.clone().add({days: -7})
          let end = date.clone().add({days: 7})
          let i = 0
          while (start < end) {
            let find = a.find(e => e.date === start.valueOf())
            if (find) {
              if (i >= 7) find.color = 'danger'
            } else {
              a.push({
                data: this.$t('death.shrimp') + ': ' + b.data.amount,
                date: this.$startDay(start).valueOf(),
                color: i < 7 ? 'warning' : 'danger'
              })
            }
            start = start.add({days: 1})
            i++
          }
          return a
        }, [])
      return null
    },

    deathDataByDate () {
      return this._.cloneDeep(this.deathData).map(e=> {
        e.data = e.data.reduce((a,b) => {
          a.amount += b.amount
          a.weight += b.weight
          return a
        })
        return e
      })
    },

    harvestData() {
      return this._.cloneDeep(this.data.harvest).filter(e => e.pond.pond_id === this.selectedPondId)
    },

    productChartData() {
      if (this.chart) return this._.cloneDeep(this.chart).filter(e => e.pond_id === this.selectedPondId)
      return null
    },

    samplingData() {
      if (this.data.sampling) return this._.cloneDeep(this.data.sampling).filter(f => f.pond_id === this.selectedPondId)
      return null
    },

    wqsOverride() {
      return [
        {
          name: 'ph',
          min_value: 7.5,
          max_value: 8.5,
        },
        {
          name: 'ph_morning_to_evening',
          max_value: 0.5,
        },
        {
          name: 'temperature',
          min_value: 25,
          max_value: 32,
        },
        {
          name: 'turbidity',
          min_value: 20,
          max_value: 50,
        },
        {
          name: 'orp',
          min_value: 150,
        },
        {
          name: 'alkalinity',
          min_value: 120,
        },
        {
          name: 'po4',
          max_value: 150,
        },
        {
          name: 'no3',
          max_value: 59.99,
        },
        {
          name: 'no2',
          max_value: .099999999999,
        },
        {
          name: 'nh3',
          max_value: 150,
        },
        {
          name: 'tbc',
          min_value: 1000000,
        },
        {
          name: 'gvc',
          max_value: 199,
        },
        {
          name: 'yvc',
          max_value: 2999,
        },
        {
          name: 'bvc',
          max_value: 0,
        },
        {
          name: 'lbc',
          max_value: 100,
        },
        {
          name: 'tom',
          max_value: 79.99,
        },
        {
          name: 'tan',
          max_value: .099999999999,
        },
        {
          name: 'co3',
          max_value: 30,
        },
        {
          name: 'hco3',
          min_value: 90,
        },
        {
          name: 'ca',
          min_value: 400,
        },
        {
          name: 'mg',
          min_value: 1200,
        },
        {
          name: 'hard',
          min_value: 4000,
          max_value: 8000,
        },
        {
          name: 'ga',
          min_value: 80.01,
        },
        {
          name: 'bga',
          max_value: 9.99,
        },
        {
          name: 'dia',
          max_value: 4.99,
        },
        {
          name: 'dino',
          max_value: 4.99,
        },
      ]
    },

    dataByDate() {
      return this.allDate.map(e => {
        let sampling =  this.samplingData && this.samplingData.length ? this.samplingData[0].samplings.filter(f => this.$timestamp(this.$startDay(f.sampling_date)) === e) : null
        return {
          wq: this.wqData ? this.wqData.find(f => f.date === e) : null,
          wqStandar: this.wqStandar ? this.wqStandar.find(f => f.date === e) : null,
          death: this.deathData ? this.deathData.find(f => f.date === e) : null,
          product: this.productsData ? this.productsData.find(f => f.date === e) : null,
          sampling: sampling && sampling.length ? sampling : null,
          date: e
        }
      }).filter(e=> e.wq || e.death || e.product || e.sampling)
    },
  },
  created() {
    let viewport = document.querySelector('meta[name="viewport"]')
    viewport.setAttribute('content', 'width=1024')

    document.title = 'SgaraReport - Generating'

    this.loadData()
  },
  methods: {
    ...mapActions('farm', [
      'getWaterQualityAnalysis',
      'getWaterQualityStandar',
      'getProductsAnalysis',
      'getFeedIndexSetting',
      'getFeedChartDaily',
      'getAccumulativeChartDaily',
      'getSamplingChartAnalysis',
      'getDeathByFarm',
      'getDisease',
      'getHarvest',
      'getWarehouseHistory',
      'getUsedItemList',
    ]),

    updateProgress() {
      ++this.done
      this.progress = (this.done / this.totalPromises) * 100
    },

    async print() {
      let generate = document.getElementById('print')
      let hidePrint = document.querySelectorAll('.hide-print')
      for (let i = 0; i < hidePrint.length; i++) {
        hidePrint[i].style.display = 'none'
      }
      generate.style.display = 'none'
      window.print()
      generate.style.display = ''
      for (let i = 0; i < hidePrint.length; i++) {
        hidePrint[i].style.display = ''
      }
    },

    getWqSetting(type) {
      return this.wqs.find(e => e.name === type)
    },

    async loadData() {
      this.loading = true

      this.selectedPondId = this.pondsOptions[0].value

      this.promises = []

      let first_promises = []

      for (let i = 0; i < this.ponds.length; i++) {
        let pond = this.ponds[i]

        first_promises.push(new Promise(resolve => {
          this.$store.dispatch('farm/getCycleById', pond.cycle_id).then(res => {
            if (!res.error && res.data)
              this.cycles = this.cycles.concat(res.data)
            resolve(true)
          })
        }))
        first_promises.push(new Promise(resolve => {
          this.$store.dispatch('farm/getStockOverview', pond).then(res => {
            this.stocks = this.stocks.concat(res).filter(e => this.cycle_ids.includes(e.cycle_id))
            resolve(true)
          })
        }))
      }

      await Promise.all(first_promises)

      this.promises.push(new Promise(resolve => {
        this.$store.dispatch('farm/getWqs', this.selectedFarm.farm_id).then(res => {
          if (!res.error && res.data && res.data.length) {
            this.wqs = res.data
          }
          for (let i = 0; i < this.wqsOverride.length; i++) {
            let wqsOverride = this.wqsOverride[i]
            let find = this.wqs.find(e => e.name === wqsOverride.name)
            if (find) {
              this.wqs.splice(this.wqs.indexOf(find), 1)
            }
            this.wqs.push(wqsOverride)
          }
          resolve({
            type: 'wqs',
            status: true
          })
        }).finally(() => {
          this.updateProgress()
        })
      }))
      this.promises.push(new Promise(resolve => {
        this.$store.dispatch('farm/getFeedIndexSetting', this.selectedFarm.farm_id).then(res => {
          if (!res.error && res.data) this.feedIndexSetting = res.data

          resolve({
            type: 'feed-index',
            status: true
          })
        }).finally(() => {
          this.updateProgress()
        })
      }))

      let start_date = this.$moment(this.start_date)
      let end_date = this.$moment(this.end_date)
      let date = start_date.clone()

      while (date.valueOf() <= end_date.valueOf()) {
        let current_date = date.clone().startOf('day').valueOf()

        this.allDate.push(current_date)

        let payload = {
          date: current_date,
          pond_ids: this.pond_ids
        }

        // Get Water
        this.promises.push(new Promise(resolve => {
          this.getWaterQualityAnalysis(payload).then(res => {
            res.data = res.data.filter(e => e.general.length || e.chemical.length || e.bacteria.length)

            resolve({
              type: 'wq',
              date: current_date,
              data: res,
            })
          }).catch(() => {
            resolve(false)
          }).finally(() => {
            this.updateProgress()
          })
        }))

        // Get Product
        this.promises.push(new Promise(resolve => {
          this.getProductsAnalysis(payload).then(res => {
            resolve({
              type: 'product',
              date: current_date,
              data: res,
            })
          }).catch(() => {
            resolve(false)
          }).finally(() => {
            this.updateProgress()
          })
        }))
        this.promises.push(new Promise(resolve => {
          this.getWaterQualityStandar(payload).then(res => {
            resolve({
              type: 'wq-standar',
              date: current_date,
              data: res,
            })
          }).catch(() => {
            resolve(false)
          }).finally(() => {
            this.updateProgress()
          })
        }))

        // Get Death
        this.promises.push(new Promise(resolve => {
          this.getDeathByFarm(payload).then(res => {
            resolve({
              type: 'death',
              date: current_date,
              data: res,
            })
          }).catch(() => {
            resolve(false)
          }).finally(() => {
            this.updateProgress()
          })
        }))


        date = date.add({days: 1})
      }

      // Get Feed
      this.promises.push(new Promise(resolve => {
        this.getFeedChartDaily({date: end_date.valueOf()}).then(res => {
          if (res && res.data && res.data.length)
            resolve({
              type: 'daily',
              data: res.data.filter(e => this.pond_ids.includes(e.pond_id) && e.feed_data)
            })
          else resolve(false)
        }).finally(() => {
          this.updateProgress()
        })
      }))

      // Get Accumulative
      this.promises.push(new Promise(resolve => {
        this.getAccumulativeChartDaily({date: end_date.valueOf()}).then(res => {
          if (res && res.data && res.data.length)
            resolve({
              type: 'accumulative',
              data: res.data.filter(e => this.pond_ids.includes(e.pond_id) && e.feed_data)
            })
          else resolve(false)
        }).finally(() => {
          this.updateProgress()
        })
      }))


      for (let i = 0; i < this.ponds.length; i++) {
        let pond = this.ponds[i]
        let cycle_id = pond.cycle_id
        // Get Disease
        this.promises.push(new Promise(resolve => {
          this.getDisease(cycle_id).then(res => {
            if (!res.error && res.data.length) {
              resolve({
                type: 'disease',
                pond,
                data: res.data.map(e => {
                  return {
                    ...e,
                    name: this.ponds.find(f => f.pond_id === e.pond_id).name
                  }
                })
              })
            } else resolve(false)
          }).catch(() => {
            resolve(false)
          }).finally(() => {
            this.updateProgress()
          })
        }))

        // Get Sampling
        this.promises.push(new Promise(resolve => {
          let cycle = this.cycles.find(e=>e.pond_id === pond.pond_id)

          this.getSamplingChartAnalysis({
            from: cycle.start_date,
            to: cycle.end_date || end_date.valueOf(),
            ponds: [pond.pond_id]
          }).then(res => {
            if (res && res.length) {
              resolve({
                type: 'sampling',
                data: res.filter(e => this.pond_ids.includes(e.pond_id))
              })
            } else resolve(false)
          }).catch(() => {
            resolve(false)
          }).finally(() => {
            this.updateProgress()
          })
        }))
      }

      if (this.isCycle) {
        for (let i = 0; i < this.ponds.length; i++) {
          let pond = this.ponds[i]
          let cycle_id = pond.cycle_id
          let stock = this.stocks.find(e => e.cycle_id === cycle_id)

          // Get Harvest
          if (stock) {
            this.promises.push(new Promise(resolve => {
              this.getHarvest(stock.stock_id).then(res => {
                if (!res.error && res.data.length) {
                  let partial = 1

                  let harvest_data = res.data.sort((a, b) => a.harvest_date > b.harvest_date ? 1 : -1).map(e => {
                    if (e.is_harvest_total) {
                      e.harvest_name = 'Total'
                    } else {
                      e.harvest_name = this.$t('harvest.partial') + ' ' + partial
                      ++partial
                    }

                    e.biomass = e.fresh
                    e.price = e.fresh_price
                    e.qty = e.biomass * (e.size || 1)
                    e.total_price = e.biomass * e.price

                    return e
                  })
                      .filter(e => e.fresh || e.molting || e.undersize || e.defective || e.hollow || e.other)

                  if (harvest_data.length) {
                    let molting = harvest_data.reduce((a, b) => {
                      a.biomass += b.molting
                      a.price += b.molting_price

                      return a
                    }, {
                      harvest_name: this.$t('harvest.molting'),
                      biomass: 0,
                      size: 0,
                      price: 0,
                      qty: 0,
                      total_price: 0,
                    })

                    let undersize = harvest_data.reduce((a, b) => {
                      a.biomass += b.undersize
                      a.price += b.undersize_price

                      return a
                    }, {
                      harvest_name: 'Undersize',
                      biomass: 0,
                      size: 0,
                      price: 0,
                      qty: 0,
                      total_price: 0,
                    })

                    let defective = harvest_data.reduce((a, b) => {
                      a.biomass += b.defective
                      a.price += b.defective_price

                      return a
                    }, {
                      harvest_name: this.$t('harvest.defective'),
                      biomass: 0,
                      size: 0,
                      price: 0,
                      qty: 0,
                      total_price: 0,
                    })

                    let hollow = harvest_data.reduce((a, b) => {
                      a.biomass += b.hollow
                      a.price += b.hollow_price

                      return a
                    }, {
                      harvest_name: this.$t('harvest.hollow'),
                      biomass: 0,
                      size: 0,
                      price: 0,
                      qty: 0,
                      total_price: 0,
                    })

                    let other = harvest_data.reduce((a, b) => {
                      a.biomass += b.other
                      a.price = +b.other_price

                      return a
                    }, {
                      harvest_name: this.$t('harvest.others'),
                      biomass: 0,
                      size: 0,
                      price: 0,
                      qty: 0,
                      total_price: 0,
                    })

                    if (molting.biomass) {
                      molting.total_price = molting.biomass * molting.price
                      harvest_data.push(molting)
                    }
                    if (undersize.biomass) {
                      undersize.total_price = undersize.biomass * undersize.price
                      harvest_data.push(undersize)
                    }
                    if (defective.biomass) {
                      defective.total_price = defective.biomass * defective.price
                      harvest_data.push(defective)
                    }
                    if (hollow.biomass) {
                      hollow.total_price = hollow.biomass * hollow.price
                      harvest_data.push(hollow)
                    }
                    if (other.biomass) {
                      other.total_price = other.biomass * other.price
                      harvest_data.push(other)
                    }

                    let grand = harvest_data.reduce((a, b) => {
                      a.qty += b.qty
                      a.biomass += b.biomass
                      a.total_price += b.total_price

                      return a
                    }, {
                      harvest_name: 'Grand',
                      qty: 0,
                      biomass: 0,
                      size: 0,
                      price: 0,
                      total_price: 0,
                    })
                    harvest_data.push(grand)

                    let summary = {
                      fcr: 0,
                      sr: 0,
                      kpi: 0,
                      area: pond.area,
                      pl_count: stock.pl_count, // tebar awal
                      netto: stock.netto,
                    }

                    summary = harvest_data.reduce((a, b) => {
                      if (b.harvest_name !== 'Grand') {
                        a.fcr += b.biomass
                        a.sr += b.biomass * (b.size || 1)
                        a.kpi += b.biomass
                      }

                      return a
                    }, summary)

                    resolve({
                      type: 'harvest',
                      data: harvest_data,
                      summary,
                      pond: pond,
                    })
                  } else resolve(false)
                } else resolve(false)
              }).catch(() => {
                resolve(false)
              }).finally(() => {
                this.updateProgress()
              })
            }))
          }
        }

        /*
        // Get Restock
        this.promises.push(new Promise(resolve => {
          this.getWarehouseHistory({
            farm_id: this.selectedFarm.farm_id,
            type: 'stored',
            from: this.start_date,
            to: this.end_date,
          }).then(res => {
            if (!res.error && res.data.length) {
              resolve({
                type: 'restock',
                data: res.data.sort((a, b) => a.created_at > b.created_at ? 1 : -1)
              })
            } else resolve(false)
          }).catch(() => {
            resolve(false)
          }).finally(() => {
            this.updateProgress()
          })
        }))
        // Get Removed
        this.promises.push(new Promise(resolve => {
          this.getWarehouseHistory({
            farm_id: this.selectedFarm.farm_id,
            type: 'removed',
            from: this.start_date,
            to: this.end_date,
          }).then(res => {
            if (!res.error && res.data.length) {
              resolve({
                type: 'removed',
                data: res.data.sort((a, b) => a.created_at > b.created_at ? 1 : -1)
              })
            } else resolve(false)
          }).catch(() => {
            resolve(false)
          }).finally(() => {
            this.updateProgress()
          })
        }))
         */
      }

      for (let i = 0; i < this.ponds.length; i++) {
        let pond = this.ponds[i]
        let cycle_id = pond.cycle_id

        let payload = {
          farm_id: this.selectedFarm.farm_id,
          pond_id: pond.pond_id,
          cycle_id: cycle_id,
        }

        // Get Product Usage
        this.promises.push(new Promise(resolve => {
          this.getUsedItemList(payload).then(res => {
            res.data = res.data.filter(e => e.qty)
            if (res && res.data && res.data.length) {
              resolve({
                type: 'product-usage',
                pond: pond,
                data: res.data
              })
            } else resolve(false)
          }).catch(() => {
            resolve(false)
          }).finally(() => {
            this.updateProgress()
          })
        }))
      }

      this.totalPromises += this.promises.length

      Promise.all(this.promises).then(res => {
        let wq = res.filter(e => e.type === 'wq')
        let wqStandar = res.filter(e => e.type === 'wq-standar')
        let product = res.filter(e => e.type === 'product')
        let death = res.filter(e => e.type === 'death')
        let disease = res.filter(e => e.type === 'disease')
        let sampling = res.filter(e => e.type === 'sampling')
        let harvest = res.filter(e => e.type === 'harvest')

        let usage = res.filter(e => e.type === 'product-usage')

        if (this.isCycle) {
          wq = wq.map(e => {
            e.data.data = e.data.data.filter(f => this.cycle_ids.includes(f.cycle_id))
            return e
          }).filter(e => e.data.data && e.data.data.length)
          product = product.map(e => {
            e.data.data.data = e.data.data.data.map(f => {
              f.data = f.data.filter(f => this.cycle_ids.includes(f.cycle_id))
              return f
            }).filter(f => f.data.length)
            e.data.data.probiotics = e.data.data.probiotics.filter(f => this.cycle_ids.includes(f.cycle_id))
            return e
          }).filter(e => (e.data.data.data && e.data.data.data.length))
          death = death.map(e => {
            if (e.data) e.data = e.data.filter(f => this.stock_ids.includes(f.stock_id))
            return e
          }).filter(e => e.data && e.data.length)
          if (sampling && sampling.length) {
            sampling = sampling.reduce((a,b) => {
              a.data = a.data.concat(b.data)
              return a
            }, {
              type: 'sampling',
              data: []
            })
            sampling.data = sampling.data.map(e => {
              if (e.samplings) e.samplings = e.samplings.filter(f => this.stock_ids.includes(f.stock_id))
              return e
            }).filter(e => e.samplings && e.samplings.length).sort((a, b) => a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: 'base',
            }))
          }
        }

        death = death.map(e => {
          if (e.data) {
            e.data = e.data.map(f => {
              let stock = this.stocks.find(g => g.stock_id === f.stock_id)
              if (stock) f.pl_source = stock.pl_source
              return f
            })
          }
          return e
        })

        let wqDate = wq.map(e => e.date)

        wqStandar = wqStandar.filter(e => wqDate.includes(e.date))

        let options = {
          chart: {
            type: 'area',
            height: 250,
            zoom: {
              enabled: false
            },
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false,
              tools: {
                download: false,
              },
            },
          },
          colors: ['#FF4060'],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            type: 'datetime',
          },
          legend: {
            show: false
          },
          tooltip: {
            enabled: false,
          },
        }

        let wq_charts = this.ponds.map(e => {
          let response = null

          let wq_data = this._.cloneDeep(wq).map(f => {
            return {
              ...f.data.data.find(g => g.pond_id === e.pond_id),
              date: f.date,
            }
          }).filter(f => f && ((f.general && f.general.length && f.general.find(g => g.show !== false)) || f.chemical || f.bacteria))

          if (wq_data && wq_data.length) {
            response = {
              pond_id: e.pond_id,
              pond_name: e.name,
            }

            let general_chart = {
              name: this.$t('wq.general'),
              data: [],
              options: this._.cloneDeep(options),
            }

            // General
            let general_fields = ['ph', 'DO', 'salinity', 'water_height', 'temperature', 'turbidity', 'oxygen', 'orp']
            let charts_morning = [
              this._.cloneDeep(general_chart),
              this._.cloneDeep(general_chart),
              this._.cloneDeep(general_chart),
              this._.cloneDeep(general_chart),
              this._.cloneDeep(general_chart),
              this._.cloneDeep(general_chart),
              this._.cloneDeep(general_chart),
              this._.cloneDeep(general_chart),
            ]

            for (let i = 0; i < general_fields.length; i++) {
              let wqSetting = this.getWqSetting(general_fields[i])
              charts_morning[i].name = general_fields[i]
              if (wqSetting) charts_morning[i].options.annotations = {
                position: "back",
                yaxis: [
                  {
                    label: {
                      text: " "
                    },
                    y: 0,
                    y2: wqSetting.min_value,
                    fillColor: "#ec1c24"
                  },
                  {
                    label: {
                      text: " "
                    },
                    y: wqSetting.min_value,
                    y2: wqSetting.max_value,
                    fillColor: "#c4ff0e"
                  },
                  {
                    label: {
                      text: " "
                    },
                    y: wqSetting.max_value,
                    y2: wqSetting.max_limit,
                    fillColor: "#ec1c24"
                  }
                ]
              }
            }

            let charts_afternoon = this._.cloneDeep(charts_morning)
            let charts_evening = this._.cloneDeep(charts_morning)

            // Chemical
            let chemical_chart = {
              name: this.$t('wq.chemical'),
              data: [],
              options: this._.cloneDeep(options),
            }

            let chemical_fields = ['tom', 'alkalinity', 'po4', 'no3', 'no2', 'nh4', 'nh3', 'tan', 'co3', 'hco3', 'ca', 'mg', 'hard']
            let charts_chemical = [
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
              this._.cloneDeep(chemical_chart),
            ]

            for (let i = 0; i < chemical_fields.length; i++) {
              let wqSetting = this.getWqSetting(chemical_fields[i])
              charts_chemical[i].name = chemical_fields[i]
              if (wqSetting) charts_chemical[i].options.annotations = {
                position: "back",
                yaxis: [
                  {
                    label: {
                      text: " "
                    },
                    y: 0,
                    y2: wqSetting.min_value,
                    fillColor: "#ec1c24"
                  },
                  {
                    label: {
                      text: " "
                    },
                    y: wqSetting.min_value,
                    y2: wqSetting.max_value,
                    fillColor: "#c4ff0e"
                  },
                  {
                    label: {
                      text: " "
                    },
                    y: wqSetting.max_value,
                    y2: wqSetting.max_limit,
                    fillColor: "#ec1c24"
                  }
                ]
              }
            }

            // Bacteria
            let bacteria_chart = {
              name: this.$t('wq.biological'),
              data: [],
              options: this._.cloneDeep(options),
            }

            let bacteria_fields = ['tbc', 'tvc', 'yvc', 'gvc', 'bvc', 'lbc', 'tvctbc', 'bga', 'ga', 'gga', 'dino', 'dia', 'pro', 'eug', 'plankton']
            let charts_bacteria = [
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
              this._.cloneDeep(bacteria_chart),
            ]

            for (let i = 0; i < bacteria_fields.length; i++) {
              let wqSetting = this.getWqSetting(bacteria_fields[i])
              charts_bacteria[i].name = bacteria_fields[i]
              if (wqSetting) charts_bacteria[i].options.annotations = {
                position: "back",
                yaxis: [
                  {
                    label: {
                      text: " "
                    },
                    y: 0,
                    y2: wqSetting.min_value,
                    fillColor: "#ec1c24"
                  },
                  {
                    label: {
                      text: " "
                    },
                    y: wqSetting.min_value,
                    y2: wqSetting.max_value,
                    fillColor: "#c4ff0e"
                  },
                  {
                    label: {
                      text: " "
                    },
                    y: wqSetting.max_value,
                    y2: wqSetting.max_limit,
                    fillColor: "#ec1c24"
                  }
                ]
              }
            }

            for (let i = 0; i < wq_data.length; i++) {
              let wq_detail = wq_data[i]

              if (wq_detail.general)
                for (let j = 0; j < wq_detail.general.length; j++) {
                  let general = wq_detail.general[j]
                  let time = parseInt(this.$timeFormat(general.created_at, 'HH'))
                  if (time < 12) {
                    for (let k = 0; k < general_fields.length; k++) {
                      let field = general_fields[k]
                      if (general[field] && general[field] !== -99) {
                        charts_morning[k].data.push({
                          x: general.created_at,
                          y: general[field]
                        })
                      }
                    }
                  } else if (time >= 12 && time < 18) {
                    for (let k = 0; k < general_fields.length; k++) {
                      let field = general_fields[k]
                      if (general[field] && general[field] !== -99) {
                        charts_afternoon[k].data.push({
                          x: general.created_at,
                          y: general[field]
                        })
                      }
                    }
                  } else if (time >= 18) {
                    for (let k = 0; k < general_fields.length; k++) {
                      let field = general_fields[k]
                      if (general[field] && general[field] !== -99) {
                        charts_evening[k].data.push({
                          x: general.created_at,
                          y: general[field]
                        })
                      }
                    }
                  }
                }

              if (wq_detail.chemical)
                for (let j = 0; j < chemical_fields.length; j++) {
                  if (wq_detail.chemical[chemical_fields[j]] && wq_detail.chemical[chemical_fields[j]] !== -99) charts_chemical[j].data.push({
                    x: wq_detail.chemical.created_at,
                    y: wq_detail.chemical[chemical_fields[j]]
                  })
                }

              if (wq_detail.bacteria)
                for (let j = 0; j < bacteria_fields.length; j++) {
                  if (wq_detail.bacteria[bacteria_fields[j]] && wq_detail.bacteria[bacteria_fields[j]] !== -99) charts_bacteria[j].data.push({
                    x: wq_detail.bacteria.created_at,
                    y: wq_detail.bacteria[bacteria_fields[j]]
                  })
                }
            }

            response.charts_morning = charts_morning.filter(e => e.data.length)
            response.charts_afternoon = charts_afternoon.filter(e => e.data.length)
            response.charts_evening = charts_evening.filter(e => e.data.length)
            response.charts_chemical = charts_chemical.filter(e => e.data.length)
            response.charts_bacteria = charts_bacteria.filter(e => e.data.length)
          }

          return response
        }).filter(e => e && (e.charts_morning.length || e.charts_afternoon.length || e.charts_evening.length || e.charts_chemical.length || e.charts_bacteria.length))

        let daily = res.find(e => e.type === 'daily')
        let accumulative = res.find(e => e.type === 'accumulative')

        let productChart = null

        if (daily && accumulative) {
          let feedIndexOptions = this._.cloneDeep(options)

          if (this.feedIndexSetting) {
            feedIndexOptions.annotations = {
              position: "back",
              yaxis: [
                {
                  label: {
                    text: " "
                  },
                  y: 0,
                  y2: this.feedIndexSetting.min_value,
                  fillColor: "#ec1c24"
                },
                {
                  label: {
                    text: " "
                  },
                  y: this.feedIndexSetting.min_value,
                  y2: this.feedIndexSetting.max_value,
                  fillColor: "#c4ff0e"
                },
                {
                  label: {
                    text: " "
                  },
                  y: this.feedIndexSetting.max_value,
                  y2: 20,
                  fillColor: "#ec1c24"
                }
              ]
            }
          }

          productChart = this.ponds.map(e => {
            let dailyData = daily.data.find(f => f.pond_id === e.pond_id)
            let accumulativeData = accumulative.data.find(f => f.pond_id === e.pond_id)

            return {
              pond_id: e.pond_id,
              pond_name: e.name,
              daily: !dailyData ? null : {
                series: [{
                  name: this.$t('warehouse.daily'),
                  data: dailyData.feed_data.sort((a, b) => a.created_at > b.created_at ? 1 : -1).map(f => {
                    return {
                      x: this.$moment(f.created_at).startOf('day'),
                      y: this.$number(f.qty),
                    }
                  })
                }],
                options: options,
              },
              accumulative: !accumulativeData ? null : {
                series: [{
                  name: this.$t('warehouse.accumulative'),
                  data: accumulativeData.feed_data.reduce((a, b) => {
                    let find = a.find(e => this.$startDay(e.x).valueOf() === this.$startDay(b.created_at).valueOf())

                    let value = this.field === 'Feed Index' ? b.feed_index : b.qty

                    if (!find)
                      a.push({
                        x: this.$startDay(b.created_at).valueOf(),
                        y: this.$number(value)
                      })
                    else find.y = find.y > value ? find.y : this.$number(value)

                    return a
                  }, []).sort((a, b) => a.x > b.x ? 1 : -1)
                }],
                options: options,
              },
              feed_index: !dailyData ? null : {
                series: [{
                  name: 'Feed Index',
                  data: dailyData.feed_data.sort((a, b) => a.created_at > b.created_at ? 1 : -1).map(f => {
                    return {
                      x: this.$moment(f.created_at).startOf('day'),
                      y: f.feed_index,
                    }
                  })
                }],
                options: feedIndexOptions,
              },
            }
          }).filter(e => e.daily && e.accumulative)
        }

        let total_harvest = null

        if (this.isCycle && harvest.length && productChart) {
          harvest = harvest.map(e => {
            let product = productChart.find(f => f.pond_id === e.pond.pond_id)

            if (product) {
              let accumulative = product.accumulative.series[0].data

              e.summary.feed = accumulative[accumulative.length - 1].y
              e.summary.fcr = e.summary.feed / e.summary.fcr
              e.summary.sr = e.summary.sr / e.summary.netto
              e.summary.kpi = e.summary.kpi / e.summary.area
              e.summary.kpi_ton = e.summary.kpi * 10
            } else {
              e.summary = null
            }

            return {
              data: e.data,
              summary: e.summary,
              pond: e.pond,
            }
          })

          total_harvest = harvest.reduce((a, b) => {
            if (b && b.data && b.data.length) {
              let find_harvest = b.data.find(e => e.harvest_name === 'Grand')

              if (find_harvest) {
                a.qty += find_harvest.qty
                a.biomass += find_harvest.biomass
                a.size += find_harvest.size
                a.price += find_harvest.price
                a.total_price += find_harvest.total_price
                a.area += b.summary ? b.summary.area : 0
              }
            }

            return a
          }, {
            harvest_name: 'Total Harvest Data',
            qty: 0,
            biomass: 0,
            size: 0,
            price: 0,
            total_price: 0,
            area: 0,
          })
        }

        let sort = (a, b) => a.product_name.localeCompare(b.product_name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })

        usage = usage.map(e => {
          e.data = e.data.sort((a, b) => {
            if (a.category_id === b.category_id)
              return sort(a, b)

            if (a.category_id === 5) return -1
            else if (b.category_id === 5) return 1
            if (a.category_id === 6) return -1
            else if (b.category_id === 6) return 1
            if (a.category_id === 3) return -1
            else if (b.category_id === 3) return 1

            return sort(a, b)
          })

          return e
        })

        this.data = {
          wq,
          wq_charts,
          wq_standar: wqStandar,
          product,
          death,
          disease,
          sampling: sampling ? sampling.data : null,
          harvest,
          total_harvest,
          usage,
        }

        this.chart = productChart

        this.loading = false

        let title = 'SgaraReport - ' + this.selectedFarm.name + ' ' + this.$dateFormat(this.start_date, 'YYYY_MM_DD')

        if (this.end_date) title += '-' + this.$dateFormat(this.end_date, 'YYYY_MM_DD')

        document.title = title
      })
    },
  }
}
</script>
<style lang="scss">

.progress-wrapper .progress-value {
  color: #000000;

  &.is-more-than-half {
    color: #FFFFFF;
  }
}

.b-table {
  font-size: .86rem;

  th[colspan="0"] {
    display: none;
  }

  .table {
    td, th {
      padding: .25rem .5rem;
    }
  }
}


svg, .avoid-break {
  page-break-inside: avoid;
  break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
}

@page {
  size: A4 landscape;
}

@media print {
  @page {
    size: A4 landscape;
  }

  html, body {
    width: 297mm;
    height: 210mm;
  }

  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }

  svg, .avoid-break {
    page-break-inside: avoid;
    break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

</style>
