<template>
  <div class="report-navigation hide-print">
    <div class="card">
      <div class="card-content">
        <b-menu :activable="false">
          <b-menu-list label="Overview">
            <b-menu-item :label="item.label" v-for="(item, key) in items.filter(e => !e.type)" :key="key" v-scroll-to="{
                 el: item.id,
                 lazy: false,
                 offset: -100,
                 force: true,
             }"></b-menu-item>
          </b-menu-list>
          <b-menu-list label="Data & Grafik Lainnya">
            <b-menu-item :label="item.label" v-for="(item, key) in items.filter(e => e.type === 'others')" :key="key" v-scroll-to="{
                 el: item.id,
                 lazy: false,
                 offset: -100,
                 force: true,
             }"></b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportNavigation",
  props: {
    items: Array,
  },
}
</script>

<style scoped lang="scss">
.report-navigation {
  position: fixed;
  right: 20px;
  top: 20px;
  bottom: 20px;
  z-index: 9999999999;
  .card {
    width: 250px;
  }
}
</style>
